import React from "react"
import "./getInTouch.css";

const GetInTouch = () => {
  return (
    <div className="get-in-touch">
      Leave me a <a href="mailto:rahul.vagadiya@gmail.com">note</a> 👋🏻
    </div>
  )
}
export default GetInTouch;