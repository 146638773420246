import React from "react"
import "./songs.css";
import Image from "./image";

const Songs = (props) => {
  const { title, songs } = props;

  return (
    <div className="songs">
      <h2>{title}</h2>
      {songs
        .map((song, i) => (
            <div key={i} className="song">
              <div>
                <a title={song.name} target="_blank" rel="noopener noreferrer" href={song.url} className="image-container">
                  <Image fileName={song.image} />
                </a>
              </div>
              <div className="description">
                <h3>
                  <a title={song.name} target="_blank" rel="noopener noreferrer" href={song.url}>
                    <span className="w-name">{song.name}</span>
                  </a>
                </h3>
                <div className="credits">{song.credits}</div>
                <div className="platforms">Available on&nbsp;
                {
                  song.platforms.map((platform, idx) => {
                    if (platform.url) {
                      return (
                        <React.Fragment key={idx}>
                          <a title={platform.name} target="_blank" rel="noopener noreferrer" href={platform.url}>
                            {platform.name}
                          </a>
                          {(idx+1) !== song.platforms.length ? ", " : ""}
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <React.Fragment key={idx}>
                          <span className="platform">{platform.name}</span>
                          {(idx+1) !== song.platforms.length ? ", " : ""}
                        </React.Fragment>
                      );
                    }
                  })
                }</div>
              </div>
            </div>
          )
      )}
    </div>
  )
}

export default Songs;
