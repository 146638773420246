import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = ({ fileName }) => {
  // All Images
  const data = useStaticQuery(graphql`
    query {
      images: allFile(filter: {extension: {ne: "md"}}) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
              fixed(width: 200) {
                ...GatsbyImageSharpFixed
              }
            }
            relativePath
            name
            dir
            ext
            extension
            absolutePath
            id
          }
        }
      }
    }
  `)

  const image = data.images.edges.find(edge => edge.node.relativePath === fileName)

  if (image) {
    return <Img fixed={image.node.childImageSharp.fixed}/>
  }
  return <></>;
}

export default Image
