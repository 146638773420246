import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SocialLinks from "../components/socialLinks"
import GetInTouch from "../components/getInTouch"
import Songs from "../components/songs"
import parchhaiyaanImage from '../images/parchhaiyaan-v2.png'

const MusicPage = ({ data }) => {
  const { pageData, svgFiles } = data;

  return (
    <Layout headerData={pageData.siteMetadata.pages.music}>
      <Link to="/" className="ribbon slant-up">
        <div className="content">
          Tech →
        </div>
      </Link>

      <SEO
        title={pageData.siteMetadata.pages.music.title}
        description={pageData.siteMetadata.pages.music.description}
        image={parchhaiyaanImage}
      />
      <SocialLinks svgFiles={svgFiles} links={pageData.siteMetadata.music.links}></SocialLinks>
      <hr/>
      
      <Songs title="Songs" songs={pageData.siteMetadata.music.songs}/>

      <hr/>
      <GetInTouch></GetInTouch>
    </Layout>
  )
}

export const query = graphql`
  query MusicQuery {
    pageData: site {
      siteMetadata {
        pages {
          music {
            title
            name
            description
          }
        }
        music {
          songs {
            name
            url
            image
            credits
            platforms {
              name
              url
            }
          }
          links {
            name
            url
            filename
            tooltip
            icon
          }
        }
      }
    }
    svgFiles: allFile(filter: { extension: { eq: "svg" }}) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
  }
`;

export default MusicPage;
